import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import {FontLoader} from "three/examples/jsm/loaders/FontLoader.js"
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry.js"

/**
 * Base
 */
// Debug
const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// AXES HELPER
// const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load("textures/matcaps/12.png")
matcapTexture.colorSpace = THREE.SRGBColorSpace

// MATERIAL
const material = new THREE.MeshMatcapMaterial()
material.matcap = matcapTexture

const group = new THREE.Group()

// FONTS
const fontLoader = new FontLoader()
fontLoader.load(
    "/fonts/Chakra Petch_Bold.json",
    (font) =>
    {
        const textGeometry = new TextGeometry(
            "Yeah!",
            {
                font: font,
                size: 0.75,
                height: 0.25,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 1
            }
        )

        textGeometry.center()

        const text = new THREE.Mesh(textGeometry, material)
        group.add(text)
    }    
)
scene.add(group)

console.time("d20")

const d20Geometry = new THREE.IcosahedronGeometry(1, 0)

for(let i = 0; i < 500; i++)
{    
    const d20 = new THREE.Mesh(d20Geometry, material)

    d20.position.x = (Math.random() - 0.5) * 20
    d20.position.y = (Math.random() - 0.5) * 20
    d20.position.z = (Math.random() - 0.5) * 20

    d20.rotation.x = Math.random() * Math.PI
    d20.rotation.y = Math.random() * Math.PI

    const scale = Math.random() * 0.5
    d20.scale.set(scale, scale, scale)

    scene.add(d20)
}

console.timeEnd("d20")

//gui.add(textMaterial, "wireframe")

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // ROTATION
    group.rotation.y = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()